<div *ngIf="false" xmlns="http://www.w3.org/1999/html">
  <input type="hidden" i18n-placeholder="@@__booking_verification"
         placeholder="{{ '__booking_verification' | translate}}">
  <input type="hidden" i18n-placeholder="@@__appointment_resend_sms"
         placeholder="{{ '__appointment_resend_sms' | translate}}">
  <input type="hidden" i18n-placeholder="@@__appointment_resend_code"
         placeholder="{{ '__appointment_resend_code' | translate}}">
  <input type="hidden" i18n-placeholder="@@__patientStop_feature_booking_verification_text_lu"
         placeholder="{{ '__patientStop_feature_booking_verification_text_lu' | translate}}">
  <input type="hidden" i18n-placeholder="@@__patientStop_feature_booking_verification_text_at"
         placeholder="{{ '__patientStop_feature_booking_verification_text_at' | translate}}">
  <input type="hidden" i18n-placeholder="@@__patientStop_feature_booking_verification_text_de"
         placeholder="{{ '__patientStop_feature_booking_verification_text_de' | translate}}">
  <input type="hidden" i18n-placeholder="@@__patientStop_feature_booking_verification_text_ch"
         placeholder="{{ '__patientStop_feature_booking_verification_text_ch' | translate}}">
  <input type="hidden" i18n-placeholder="@@__patientStop_feature_booking_verification_text_nl"
         placeholder="{{ '__patientStop_feature_booking_verification_text_nl' | translate}}">
  <input type="hidden" i18n-placeholder="@@__patientStop_feature_booking_verification_text_fr"
         placeholder="{{ '__patientStop_feature_booking_verification_text_fr' | translate}}">
  <input type="hidden" i18n-placeholder="@@__patientStop_feature_booking_verification_text_be"
         placeholder="{{ '__patientStop_feature_booking_verification_text_be' | translate}}">
</div>

<form [formGroup]="formGroup">
  <div class="formElement">
    <div class="col-12 col-md-6 col-lg-4 d-inline-block">
      <label class="col-12 control-label" for="code"
             i18n="@@__form_verify_code_legend">{{'__form_verify_code_legend' | translate}}</label>
      <mat-form-field class="col-11">
        <input type="number" matInput formControlName="code" required id="code"
               (keydown)="bookingService.monitorKeyInput($event, formGroup.value.code, 'true')">
      </mat-form-field>
      <br>
      <small class="col-12 form-text text-muted" i18n="@@__confirmation_code_help"
             [innerHtml]="helpText() | translate | sprintf: calendarService.selectedAgenda?.practice?.phone">
      </small>
    </div>
    <div class="col-12 col-md-5 d-inline-block resendSms" *ngIf="resendSms">
      <span i18n="@@__appointment_sms_not_received">{{'__appointment_sms_not_received' | translate}}</span>
      <a rel="nofollow" href="javascript:void(0)" i18n="@@__appointment_resend_sms"
         (click)="resendSmsCode()">{{resendButtonLabel() | translate}}</a>
    </div>
  </div>

  <div class="col-12 action-buttons">
    <button class="btn-secondary float-left" mat-button matStepperPrevious
            i18n="@@__label_back">{{ '__label_back' | translate }}
    </button>
    <button class="btn-success float-right" mat-button matStepperNext
            i18n="@@__label_next"> {{ '__label_next' | translate }}
    </button>
  </div>
</form>
