<div *ngIf="false" xmlns="http://www.w3.org/1999/html">
  <input type="hidden" i18n-placeholder="@@__legend_field_required"
         placeholder="{{ '__legend_field_required' | translate}}">
  <input type="hidden" i18n-placeholder="@@__name_min_char_legend"
         placeholder="{{ '__name_min_char_legend' | translate}}">
  <input type="hidden" i18n-placeholder="@@__legend_valid_email"
         placeholder="{{ '__legend_valid_email' | translate}}">
  <input type="hidden" i18n-placeholder="@@__legend_valid_number"
         placeholder="{{ '__legend_valid_number' | translate}}">
  <input type="hidden" i18n-placeholder="@@__legend_mobile_number_required"
         placeholder="{{ '__legend_mobile_number_required' | translate}}">
  <input type="hidden" i18n-placeholder="@@__signup_gdpr_shortdesc_v2_policy"
         placeholder="{{ '__signup_gdpr_shortdesc_v2_policy' | translate}}">
  <input type="hidden" i18n-placeholder="@@__signup_gdpr_shortdesc_v2_termAndConditions"
         placeholder="{{ '__signup_gdpr_shortdesc_v2_termAndConditions' | translate}}">
  <input type="hidden" i18n-placeholder="@@__booking_patient_info_step2"
         placeholder="{{ '__booking_patient_info_step2' | translate}}">
  <input type="hidden" i18n-placeholder="@@__matricule_error_invalid"
         placeholder="{{ '__matricule_error_invalid' | translate}}">
  <input type="hidden" i18n-placeholder="@@__matricule_error_invalid_be"
         placeholder="{{ '__matricule_error_invalid_be' | translate}}">
  <input type="hidden" i18n-placeholder="@@__matricule_error_invalid_de"
         placeholder="{{ '__matricule_error_invalid_de' | translate}}">
  <input type="hidden" i18n-placeholder="@@__matricule_error_invalid_ch"
         placeholder="{{ '__matricule_error_invalid_ch' | translate}}">
  <input type="hidden" i18n-placeholder="@@__matricule_error_invalid_nl"
         placeholder="{{ '__matricule_error_invalid_nl' | translate}}">
  <input type="hidden" i18n-placeholder="@@__form_appointment_matricule"
         placeholder="{{ '__form_appointment_matricule' | translate}}">
  <input type="hidden" i18n-placeholder="@@__form_appointment_matricule_be"
         placeholder="{{ '__form_appointment_matricule_be' | translate}}">
  <input type="hidden" i18n-placeholder="@@__form_appointment_matricule_de"
         placeholder="{{ '__form_appointment_matricule_de' | translate}}">
  <input type="hidden" i18n-placeholder="@@__form_appointment_matricule_ch"
         placeholder="{{ '__form_appointment_matricule_ch' | translate}}">
  <input type="hidden" i18n-placeholder="@@__form_appointment_matricule_nl"
         placeholder="{{ '__form_appointment_matricule_nl' | translate}}">
</div>

<form [formGroup]="formGroup">
  <h5 class="col-12" i18n="@@__form_information_patient_legend">
    {{ '__form_information_patient_legend' | translate }}
  </h5>

  <!-- patient name -->
  <div class="formElement">
    <label class="col-12 no-mg-bottom" i18n="@@__name_patient">
      <strong>{{ '__name_patient' | translate }}</strong>
    </label>

    <!-- patient first name -->
    <mat-form-field class="col-12 col-md-5 d-inline-block">
      <mat-label i18n="@@__user_first_name">{{ '__user_first_name' | translate}}</mat-label>
      <input matInput formControlName="patientFirstName" required [readonly]="isBingli()"
             (keydown)="bookingService.monitorKeyInput($event, formGroup.value['patientFirstName'])">
      <mat-error
        *ngIf="formGroup.controls.patientFirstName.hasError('required') && !formGroup.controls.patientFirstName.hasError('pattern')">
        {{ '__legend_field_required' | translate }}
      </mat-error>
      <mat-error
        *ngIf="!formGroup.controls.patientFirstName.hasError('required') && formGroup.controls.patientFirstName.hasError('pattern')">
        {{ '__name_min_char_legend' | translate }}
      </mat-error>
    </mat-form-field>

    <!-- patient last name -->
    <mat-form-field class="col-12 col-md-5 d-inline-block float-right">
      <mat-label i18n="@@__user_last_name">{{ '__user_last_name' | translate}}</mat-label>
      <input matInput formControlName="patientLastName" required [readonly]="isBingli()"
             (keydown)="bookingService.monitorKeyInput($event, formGroup.value['patientLastName'])">
      <mat-error
        *ngIf="formGroup.controls.patientLastName.hasError('required') && !formGroup.controls.patientLastName.hasError('pattern')">
        {{ '__legend_field_required' | translate }}
      </mat-error>
      <mat-error
        *ngIf="!formGroup.controls.patientLastName.hasError('required') && formGroup.controls.patientLastName.hasError('pattern')">
        {{ '__name_min_char_legend' | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <!-- gender radio input -->
  <div class="formElement">
    <mat-form-field id="genderInputForm" class="col-12 col-md-5">
      <mat-label i18n="@@__label_gender">{{ '__label_gender' | translate}}</mat-label>
      <mat-select id="patientGender" formControlName="patientGender" class="mat-select- -no-underline" required>
        <mat-option value="male">{{ '__gender_male' | translate }}</mat-option>
        <mat-option value="female">{{ '__gender_female' | translate }}</mat-option>
        <mat-option value="other">{{ getGenderOtherLabel() | translate }}</mat-option>
        <mat-option value="prefer_not_to_say">{{ '__gender_prefer_not_to_say' | translate }}</mat-option>
      </mat-select>
      <mat-error class="col-12"
                 *ngIf="formGroup.controls.patientGender.hasError('required')">
        {{ '__legend_field_required' | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <!-- birthday datepicker -->
  <div class="formElement">
    <mat-form-field id="patientBirthday" class="col-12 col-md-5">
      <mat-label i18n="@@__form_appointment_birthday"> {{ '__form_appointment_birthday' | translate }}</mat-label>
      <input
        matInput
        formControlName="patientBirthday" required
        placeholder="{{'DD'+datePickerDelimeter+'MM'+datePickerDelimeter+'YYYY'}}"
        [readonly]="isBingli()"
        [max]="calendarService?.today?.toJSDate()"
        [matDatepicker]="picker"
        (dateChange)="onDateChange($event)"
        (keydown)="bookingService.monitorKeyInput($event, formGroup.value['patientBirthday'])">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker [disabled]="isBingli()"></mat-datepicker>
      <mat-error *ngIf="formGroup.controls.patientBirthday.hasError('required')">
        {{ '__legend_field_required' | translate }}
      </mat-error>
      <mat-error
        *ngIf="!formGroup.controls.patientBirthday.hasError('required') && formGroup.controls.patientBirthday.invalid">
        {{ '__legend_valid_date' | translate }}
      </mat-error>

    </mat-form-field>
  </div>

  <!-- matricule -->
  <div class="formElement" *ngIf="formGroup?.controls?.matricule">
    <div class="matriculeFormElement col-12 col-md-5">
      <app-matricule-input *ngIf="customFields.matricule || userData" [(formGroup)]="formGroup"
                           [visibleCountries]="matriculeVisibleCountries" [required]="true"
                           autoSelectedCountry="{{initialMatriculeCountry}}" [enableSearch]="false" [textLabels]="{
                                   requiredError: '__legend_field_required' | translate,
                                   minLengthError: '__name_min_char_legend' | translate,
                                   maxLengthError: '__name_max_char_legend' | translate,
                                   invalidSsnError: '__matricule_error_invalid' | translate,
                                   mainLabel: getMatriculeLabel() | translate
                                   }" [autoSelectedCountry]="countryIso"
                           (keydown)="bookingService.monitorKeyInput($event, formGroup.value['matricule'])">
      </app-matricule-input>
    </div>
  </div>

  <!-- CUSTOM FIELDS FOR PATIENT -->
  <app-fieldset *ngIf="customFields.patient" [fields]="customFields.patient.fields"
                [parentForm]="formGroup" [isPristine]="true"
                (keyDownEvent)="bookingService.monitorKeyInput($event)">
  </app-fieldset>

  <!-- User name -->
  <div *ngIf="formGroup.controls.userFirstName" class="major-person-information">
    <h5 class="major-person-title"
        i18n="@@__form_appointment_contact_legend">{{ '__form_appointment_contact_legend' | translate }}</h5>

    <p class="major-person-desc"
       i18n="@@__form_appointment_message_underage">{{ '__form_appointment_message_underage' | translate | sprintf: String(bookingService.majorityAgeMin) }}</p>

    <div class="formElement">
      <!-- User first name -->
      <mat-form-field class="col-12 col-md-5 d-inline-block">
        <mat-label i18n="@@__user_first_name">{{ '__user_first_name' | translate}}</mat-label>
        <input matInput formControlName="userFirstName" required
               (keydown)="bookingService.monitorKeyInput($event, formGroup.value['userFirstName'])">
        <mat-error
          *ngIf="formGroup.controls.userFirstName.hasError('required') && !formGroup.controls.userFirstName.hasError('pattern')">
          {{ '__legend_field_required' | translate }}
        </mat-error>
        <mat-error
          *ngIf="!formGroup.controls.userFirstName.hasError('required') && formGroup.controls.userFirstName.hasError('pattern')">
          {{ '__name_min_char_legend' | translate }}
        </mat-error>
      </mat-form-field>

      <!-- User last name -->
      <mat-form-field class="col-12 col-md-5 d-inline-block float-right">
        <mat-label i18n="@@__user_last_name">{{ '__user_last_name' | translate}}</mat-label>
        <input matInput formControlName="userLastName" required
               (keydown)="bookingService.monitorKeyInput($event, formGroup.value['userLastName'])">
        <mat-error
          *ngIf="formGroup.controls.userLastName.hasError('required') && !formGroup.controls.userLastName.hasError('pattern')">
          {{ '__legend_field_required' | translate }}
        </mat-error>
        <mat-error
          *ngIf="!formGroup.controls.userLastName.hasError('required') && formGroup.controls.userLastName.hasError('pattern')">
          {{ '__name_min_char_legend' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <!-- Email -->
  <div class="formElement">
    <mat-form-field class="col-12 col-md-5">
      <mat-label i18n="@@__form_appointment_email">{{ '__form_appointment_email' | translate}}</mat-label>
      <input matInput type="email" id="userEmail" formControlName="userEmail"
             required (keydown)="bookingService.monitorKeyInput($event, formGroup.value['userEmail'])">
      <mat-error
        *ngIf="formGroup.controls.userEmail.hasError('email') && !formGroup.controls.userEmail.hasError('required')">
        {{ '__legend_valid_email' | translate }}
      </mat-error>
      <mat-error *ngIf="formGroup.controls.userEmail.hasError('required')">
        {{ '__legend_field_required' | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <!-- mobile phone number -->
  <div class="formElement">
    <div class="mobileFormElement col-12 col-md-5">
      <app-phone-input [(formGroup)]="formGroup" [fieldControlName]="'userMobile'" [required]="true"
                       [preferredCountries]="phonePreferredCountries"
                       [textLabels]="{
                                   requiredError: '__legend_field_required' | translate,
                                   invalidNumberError: '__legend_valid_number' | translate,
                                   invalidMobileNumberError: '__legend_mobile_number_required' | translate
                                   }"
                       [autoIpLookup]="false" [iconMakeCall]="false" [includeDialCode]="true"
                       [autoSelectedCountry]="countryIso"
                       (keydown)="bookingService.monitorKeyInput($event, formGroup.value['userMobile'])">
      </app-phone-input>
    </div>
  </div>

  <!-- CUSTOM QUESTIONNAIRE or CUSTOM QUESTIONS -->
  <div class="custom-questions" *ngIf="customFields.questions">
    <h5 i18n="@__form_appointment_questions_legend" class="col-12 mg-top">
      {{ '__form_appointment_questions_legend' | translate }}
    </h5>
    <app-fieldset *ngIf="customFields.questions" [fields]="customFields.questions.fields"
                  [parentForm]="formGroup" [isPristine]="isPristine"
                  (keyDownEvent)="bookingService.monitorKeyInput($event)">
    </app-fieldset>
  </div>

  <!-- Checkbox create patient account -->
  <div class="formElement" *ngIf="!patientService.patient && !isReschedule && !userData && bookingLoginDoctenaAccount">
    <mat-checkbox class="col-12" formControlName="createPatientAccount">
      <label i18n="@@__form_appointment_new_account">
        {{ '__form_appointment_new_account' | translate }}
      </label>
    </mat-checkbox>
  </div>

  <!-- Checkbox LMA -->
  <div class="formElement lma-box"
       *ngIf="patientService.patient && calendarService.selectedAgenda.subscribed_lma && isSlotInOneDay">
    <label i18n="@@__be_notified_earlier_availability_v2" class="lma-question">
      <b>{{ '__be_notified_earlier_availability_v2' | translate }}</b>
    </label>
    <mat-checkbox class="col-12" formControlName="notifyLma">
      <label i18n="@@__be_notified_earlier_availability_yes"
             [innerHtml]="'__be_notified_earlier_availability_yes' | translate">
      </label>
    </mat-checkbox>
  </div>

  <br>

  <!-- CGU + Policy Agreements -->
  <div class="formElement termsFormElement">
            <span i18n="@@__signup_gdpr_shortdesc_v2"
                  [innerHTML]="'__signup_gdpr_shortdesc_v2' | translate | sprintf: coreProcessingTags[0] : coreProcessingTags[1]">
            </span>
    <br><br>
    <div id="core_service_processing" *ngIf="isShowCoreProcessing">
      <table>
        <!--              Activity -->
        <tr>
          <th i18n="@@__core_service_processing_activity_title"
              [innerHTML]="'__core_service_processing_activity_title' | translate"></th>
          <th i18n="@@__core_service_processing_data_characteristics_title"
              [innerHTML]="'__core_service_processing_data_characteristics_title' | translate"></th>
          <th i18n="@@__core_service_processing_legal_basis_title"
              [innerHTML]="'__core_service_processing_legal_basis_title' | translate"></th>
        </tr>
        <!--              Patient data -->
        <tr>
          <td i18n="@@__processing_activity_patient_data"
              [innerHTML]="'__processing_activity_patient_data' | translate"></td>
          <td i18n="@@__data_characteristics_processing_activity_patient_data"
              [innerHTML]="'__data_characteristics_processing_activity_patient_data' | translate"></td>
          <td i18n="@@__legal_basis_processing_activity_patient_data"
              [innerHTML]="'__legal_basis_processing_activity_patient_data' | translate"></td>
        </tr>
        <!--              Medical history questions -->
        <tr>
          <td i18n="@@__processing_activity_medical_history"
              [innerHTML]="'__processing_activity_medical_history' | translate"></td>
          <td i18n="@@__data_characteristics_processing_medical_history"
              [innerHTML]="'__data_characteristics_processing_medical_history' | translate"></td>
          <td i18n="@@__legal_basis_processing_medical_history"
              [innerHTML]="'__legal_basis_processing_medical_history' | translate"></td>
        </tr>
        <!--              Reason of visits -->
        <tr>
          <td i18n="@@__processing_activity_reason_of_visit"
              [innerHTML]="'__processing_activity_reason_of_visit' | translate"></td>
          <td i18n="@@__data_characteristics_processing_activity_reason_of_visit"
              [innerHTML]="'__data_characteristics_processing_activity_reason_of_visit' | translate"></td>
          <td i18n="@@__legal_basis_processing_activity_reason_of_visit"
              [innerHTML]="'__legal_basis_processing_activity_reason_of_visit' | translate"></td>
        </tr>
        <!--              Contract data -->
        <tr>
          <td i18n="@@__processing_activity_verification_of_your_contract_data"
              [innerHTML]="'__processing_activity_verification_of_your_contract_data' | translate"></td>
          <td i18n="@@__data_characteristics_processing_activity_verification_of_your_contract_data"
              [innerHTML]="'__data_characteristics_processing_activity_verification_of_your_contract_data' | translate"></td>
          <td i18n="@@__legal_basis_processing_activity_verification_of_your_contract_data"
              [innerHTML]="'__legal_basis_processing_activity_verification_of_your_contract_data' | translate"></td>
        </tr>
        <!--              Notifications -->
        <tr>
          <td i18n="@@__processing_activity_notifications"
              [innerHTML]="'__processing_activity_notifications' | translate"></td>
          <td i18n="@@__data_characteristics_processing_activity_notifications"
              [innerHTML]="'__data_characteristics_processing_activity_notifications' | translate"></td>
          <td i18n="@@__legal_basis_processing_activity_notifications"
              [innerHTML]="'__legal_basis_processing_activity_notifications' | translate"></td>
        </tr>
        <!--              Feedback -->
        <tr>
          <td i18n="@@__processing_activity_feedback"
              [innerHTML]="'__processing_activity_feedback' | translate"></td>
          <td i18n="@@__data_characteristics_processing_activity_feedback"
              [innerHTML]="'__data_characteristics_processing_activity_feedback' | translate"></td>
          <td i18n="@@__legal_basis_processing_activity_feedback"
              [innerHTML]="'__legal_basis_processing_activity_feedback' | translate"></td>
        </tr>
        <!--              Health insurance -->
        <tr>
          <td i18n="@@__processing_activity_health_insurance"
              [innerHTML]="'__processing_activity_health_insurance' | translate"></td>
          <td i18n="@@__data_characteristics_processing_health_insurance"
              [innerHTML]="'__data_characteristics_processing_health_insurance' | translate"></td>
          <td i18n="@@__legal_basis_processing_health_insurance"
              [innerHTML]="'__legal_basis_processing_health_insurance' | translate"></td>
        </tr>
        <!--              Video consultation -->
        <tr>
          <td i18n="@@__processing_video_consultation"
              [innerHTML]="'__processing_video_consultation' | translate"></td>
          <td i18n="@@__data_characteristics_video_consultation"
              [innerHTML]="'__data_characteristics_video_consultation' | translate"></td>
          <td i18n="@@__legal_basis_processing_video_consultation"
              [innerHTML]="'__legal_basis_processing_video_consultation' | translate"></td>
        </tr>
      </table>
      <p i18n="@@__processing_activity_notes_v2" [innerHTML]="'__processing_activity_notes_v2' | translate"></p>
    </div>
  </div>
  <div class="formElement formElement--alert semi-mg-top">
    <mat-checkbox formControlName="cguAgreement" required>
      <label i18n="@@__signup_gdpr_shortdesc_v2_termAndConditions"
             [innerHTML]="'__signup_gdpr_shortdesc_v2_termAndConditions' | translate | sprintf:
                termsLinksTags[0] : termsLinksTags[4]">
        <mat-error class="col-1 d-inline-block"
                   *ngIf="isPristine && formGroup.controls.cguAgreement.hasError('required')">
          *
        </mat-error>
      </label>
    </mat-checkbox>
    <mat-error
      *ngIf="isPristine && formGroup.controls.cguAgreement.hasError('required')">
      {{ '__legend_field_required' | translate }}
    </mat-error>
  </div>
  <div class="formElement formElement--alert semi-mg-top">
    <mat-checkbox formControlName="privacyPolicyAgreement" required>
      <label i18n="@@__signup_gdpr_shortdesc_v2_policy"
             [innerHTML]="'__signup_gdpr_shortdesc_v2_policy' | translate | sprintf:
              termsLinksTags[1] : termsLinksTags[4]">
        <mat-error class="col-1 d-inline-block"
                   *ngIf="isPristine && formGroup.controls.privacyPolicyAgreement.hasError('required')">
          *
        </mat-error>
      </label>
    </mat-checkbox>
    <mat-error
      *ngIf="isPristine && formGroup.controls.privacyPolicyAgreement.hasError('required')">
      {{ '__legend_field_required' | translate }}
    </mat-error>
  </div>
  <br>
  <span
    i18n="@@__signup_gdpr_shortdesc_v2_bottom"
    [innerHTML]="'__signup_gdpr_shortdesc_v2_bottom' | translate | sprintf: termsLinksTags[3]">
        </span>
  <br><br>
  <div class="col-12">
    <button class="btn-secondary float-left" mat-button matStepperPrevious
            i18n="@@__label_back">{{ '__label_back' | translate }}
    </button>
    <button class="btn-success float-right" mat-button matStepperNext (click)="onClickNext()"
            i18n="@@__label_next">{{ '__label_next' | translate }}
    </button>
  </div>
</form>
