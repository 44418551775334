<app-alert></app-alert>
<app-loader></app-loader>
<div *ngIf="isDisplayable()">
  <div class="doctor" id="calendar-view">
    <div id="patient-doctena-login" *ngIf="showPatientLoginButton() && (!isAccountRequestComplete && !isLogged)">
      <p>{{ '__i_want_to_book_an_appointment'| translate }}</p>
      <mat-radio-group class="example-radio-group">
        <mat-radio-button value="0" class="booking-with-doctena-radio-button"
                          (click)="isDoctenaUser(true)">
          {{ '__booking_with_doctena_account'| translate }}
        </mat-radio-button>
        <!-- Login form -->
        <app-patient-login *ngIf="showPatientLoginButton()"
                           (isLogged)="setIsLogged($event)"
                           [countryIso]="countryIso"
                           [consultationGroup]="consultationGroup"
                           [showPatientLoginForm]="showPatientLoginForm">
        </app-patient-login>
        <mat-radio-button value="1" class="booking-without-doctena-radio-button" (click)="isDoctenaUser(false)">
          {{ '__booking_without_doctena_account'| translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div *ngIf="agenda && (isAccountRequestComplete || isLogged || !showPatientLoginButton())">
      <div class="flex-container">
        <div class="item-flex">
          <app-filters
            *ngIf="agenda"
            (isBookingRulesSelected)="setStatusBookingRulesSelected($event)"
            (reasonOfVisitWithTriageKey)="setReasonOfVisitDoctenaWithTriageKey($event)"
            (isBingliRovSelected)="setIsBingliRovSelected($event)"
            [showBingliForm]="!completeBingliSurvey"
            [agenda]="agenda"
            [isDisabled]="isBookingRulesSelected && bingliFeature"
            [preselectRov]="preselectBingliRov"
            [isPracticeView]="isPracticeView">
          </app-filters>
        </div>
      </div>
      <div class="alert alert-info" *ngIf="agendaFeatures.hasOwnProperty('patientStop')">
          <strong i18n="@@__patientstop_practitioner_only_accept_existing_patients">
          {{ '__patientstop_practitioner_only_accept_existing_patients' | translate }}
        </strong>
      </div>
      <!-- BINGLI PART --->
      <div *ngIf="showBingliForm && bingliFeature && reasonOfVisitDoctenaWithTriageKey.length && isBingliRovSelected">
        <!-- Bingli Form  -->
        <app-bingli-form (onSurveyComplete)="isCompleteSurvey($event)"
                         (reasonOfVisitBingli)="extractReasonOfVisitBingli($event)"
                         [features]="agendaFeatures"
                         [entityName]="'agenda'"
                         [entityEid]="agenda.externalId"
                         [featureCode]="'triage'"
                         [preselectBingliRov]="reasonOfVisitDoctenaWithTriageKey"
                         [consentSignBy] = "doctor.name"
                         [consentSignContact] = "agenda.practice.phone">
        </app-bingli-form>
      </div>

      <app-doctor-details
        *ngIf="agenda && completeBingliSurvey"
        [agenda]="agenda"
        [hasDoctorImage]="!hidePicture"
        [isPracticeView]="isPracticeView"
        [specialitiesAliases]="specialitiesAliases">
      </app-doctor-details>
    </div>

    <div *ngIf="agenda" class="powered-by">
      <p *ngIf="!hidePowerBy">
        {{ '__powered_by' | translate}}
        <a rel="nofollow" [href]="profileUrl" target="_blank">
          Doctena
        </a>
        -
      </p>
      <a i18n="Privacy policy@@__privacy_settings" [href]="policyUrl" target="_blank" rel="nofollow">
        {{ '__privacy_settings' | translate}}
      </a>
    </div>
  </div>
  <app-cookie-info *ngIf="!isHideCookieBanner()" [countryIso]="countryIso"></app-cookie-info>
</div>
