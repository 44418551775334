import {commonEnv} from './environment.common';
import {merge} from 'lodash';

const env: Partial<typeof commonEnv> = {
  production: true,
  environmentName: 'demo',
  apiUrl: 'https://api.demo.doctena.lu',
  esiksUrl: 'https://soa.doctena.com/demo-esiks',
  ratingsApiUrl: 'https://soa.doctena.com/demo-ratings/',
  gatewayUrl: null,
  cloudinary_repo: 'doctena'
};

// Export all settings of common replaced by dev options
export const environment = merge(commonEnv, env);
